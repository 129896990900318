import React from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { H3 } from '@system'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => ({
  module: {
    padding: '4rem 0',
  },
  headline: {
    margin: 0,
    color: theme.palette.secondary.main,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 4rem 3rem',
    fontWeight: theme.typography.fontWeightLight,
    [theme.breakpoints.down('md')]: {
      padding: '0 0 24px',
    },
    display: 'block',
  },
  item: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px 10px',
    width: '25%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  }),
  itemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}))

const ModuleHeadlineWith4ColumnStats = (props) => {
  const classes = useStyles(props.blok)
  return (
    <SbEditable content={props.blok}>
      <PageContainer py={8}>
        <ContentContainer>
          <H3 className={classes.headline}>{props.blok.headline}</H3>
          <Box className={classes.itemContainer}>
            {!!props.blok.stats &&
              props.blok.stats.map((blok) => (
                <Box key={blok._uid} className={classes.item}>
                  {renderBlok(blok)}
                </Box>
              ))}
          </Box>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default ModuleHeadlineWith4ColumnStats
